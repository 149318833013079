import React, { useState } from 'react';
import './ConsultationForm.css';
import { arc, arc2 } from '../../assets/img';

import Modal from 'react-bootstrap/Modal';

const ConsultationForm = ({ show, handleCloseModal , onConsultationClick, }) => {
    const [isLoading, setLoading] = useState(true);

    const handleLoad = () => {
        setLoading(false);
    };
    return (
        <Modal show={onConsultationClick} onHide={handleCloseModal} size='xl'>
            <Modal.Header closeButton className='mt-0 mt-0'>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-5 mb-0 pb-0 mt-0 pt-0' >
                <div className='container px-md-3 px-0'>
                    <div className='row d-flex py-3'>
                        <div className='col-12 col-md-6 left-section mt-md-5 px-0'>
                            <h1 className='fw-bold customfont fs-5 '>Find out if TMS therapy is right for you or a loved one</h1>
                            <p className='fw-bold fs-6 mb-md-4 mb-2'>
                                Please provide the requested information on the form and ARC Psychiatry TMS will contact you shortly.
                            </p>
                            <p className='fw-bold fs-6 mb-mb-5 mb-2'>
                                All information will be kept strictly confidential. We do not sell or share your information.
                            </p>
                            <p className='fs-6'>
                                ** If you are a referring Physician interested in contacting ARC Psychiatry TMS about a current patient, <b className='colorchng'>please use this form.</b>
                                {/* <strong className='customColor'></strong> */}
                            </p>
                        </div>
                        <div className='col-12 col-md-6'>
                            {isLoading && (
                                <div className="loader-container">
                                    <div className="loader"></div>
                                </div>
                            )}
                            <iframe
                                title="JotForm Embed"
                                id="JotFormIFrame-232075867651362"
                                allowtransparency="true"
                                allowFullScreen={true}
                                allow="geolocation; microphone; camera"
                                src="https://archealthpartners.jotform.com/232075867651362"
                                // src="https://archealthpartners.jotform.com/jsform/232075867651362"
                                frameBorder="0"
                                style={{ width: '100%', minHeight: '1000px', border: 'none' }}
                                scrolling="yes"
                                onLoad={handleLoad}
                            >
                            </iframe>
                            {/* <div className='text-center'>
                                <img src={arc2} className='w-25' />
                                <h2 style={{color:'#98C647'}}>Thank you for your submission!</h2>
                                <p>We appreciate your interest. One of our team members will contact you shortly.</p>
                            </div> */}

                        </div>

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ConsultationForm;
